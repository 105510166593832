// Category change
const categorySelect = document.querySelector('#idCategory');
if (categorySelect) {
    categorySelect.addEventListener('change', () => {
        const xhr = new XMLHttpRequest();
        const formData = new FormData();

        formData.append('idSeason', document.querySelector('#idSeason').value);
        formData.append('idCategory', categorySelect.value);

        xhr.open('POST', categorySelect.getAttribute('data-ajax-target'), true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                document.querySelector('#idFolder').innerHTML = xhr.response;
            }
        };
        xhr.send(formData);
    });
}

// CateSeasongory change
const seasonSelect = document.querySelector('#idSeason');
if (seasonSelect) {
    seasonSelect.addEventListener('change', () => {
        const formData = new FormData();

        formData.append('idCategory', document.querySelector('#idCategory').value);
        formData.append('idSeason', seasonSelect.value);

        const xhrFolder = new XMLHttpRequest();
        xhrFolder.open('POST', seasonSelect.getAttribute('data-ajax-folder-target'), true);
        xhrFolder.onload = () => {
            if (xhrFolder.status === 200) {
                document.querySelector('#idFolder').innerHTML = xhrFolder.response;
            }
        };
        xhrFolder.send(formData);

        const xhrCategory = new XMLHttpRequest();
        xhrCategory.open('POST', seasonSelect.getAttribute('data-ajax-category-target'), true);
        xhrCategory.onload = () => {
            if (xhrCategory.status === 200) {
                document.querySelector('#idCategory').innerHTML = xhrCategory.response;
            }
        };
        xhrCategory.send(formData);
    });
}
